<template>
  <div id="app">
    <div class="app-back" @click="goBack" v-if="isBtn">
      <img src="./assets/appback.png" alt />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isBtn: true
    };
  },
  computed: {
    zoomAuto: function () {
      return document.documentElement.clientWidth / 1920;
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    isCurrentRoute(routeName) {
      return this.$route.name === routeName;
    },
    bodyScale() {
      var devicewidth = document.documentElement.clientWidth;
      var scale = devicewidth / 1920;
      document.body.style.zoom = scale;
      let strScale = `scale(${scale})`;
      var obj = {
        zoom: 1 / scale,
        transform: strScale,
        transformOrigin: "0 0"
      };
      this.insertCss("canvas", obj);
    },
    insertCss(select, styles) {
      // console.log(styles, "|App_styles|");
      if (document.styleSheets.length === 0) {
        var style = document.createElement("style");
        document.head.appendChild(style);
      }
      var styleSheet = document.styleSheets[document.styleSheets.length - 1];
      var str = select + " {";
      for (var prop in styles) {
        str +=
          prop.replace(/([A-Z])/g, function (item) {
            return "-" + item.toLowerCase();
          }) +
          ":" +
          styles[prop] +
          ";";
      }
      str += "}";
      styleSheet.insertRule(str, styleSheet.cssRules.length);
    }
  },
  mounted() {
    window.onload = window.onresize = () => {
      this.bodyScale();
    };

    //监听路由变化
    this.$router.afterEach((to, from) => {
      // console.log("|路由变化|____", to, from)
      if (this.isCurrentRoute('dangjian')) {
        // 当前路由是 'dangjian'
        // console.log("goBack__dangjian", this.$router);
        this.isBtn = false;
      } else {
        // 当前路由不是 'dangjian'
        // console.log("goBack__Nodangjian", this.$router);
        if (!this.isBtn) {
          this.isBtn = true;
        }
      }
    })
  },
};
</script>

<style>
.app-back {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(137, 172, 225, 0.35);
  border-radius: 50%;
  position: fixed;
  left: 50px;
  bottom: 42px;
  z-index: 55;
}

::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0%;
  padding: 0%;
}

div {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
}

#app {
  width: 100%;
  position: relative;
  height: 100vh;
}

@font-face {
  font-family: "MyFont";
  src: url("./assets/YouSheBiaoTiHei-2.ttf");
}

@font-face {
  font-family: "bmzy";
  src: url("./assets/bmzy.ttf");
}

@font-face {
  font-family: "Alimama";
  src: url("./assets/AlimamaFangYuanTiVF-Thin.ttf");
}

@font-face {
  font-family: "pmzd";
  src: url("./assets/pmzd.ttf");
}

.www {
  animation: move 0.5s linear 1 !important;
  animation-fill-mode: forwards !important;
}

@keyframes move {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}
</style>
